import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

const fetchLinkPreviewOembedRCE = link => (dispatch, getState, { oembedRequestRCE }) => {
  return oembedRequestRCE(`?url=${link}`).then(json => {
    const { html, provider_name, ...rest } = json;
    return { ...rest };
  });
};

export const fetchLinkPreviewOembedRCEPromisified = createPromisifiedAction(
  fetchLinkPreviewOembedRCE,
  d => d,
  () => {},
);

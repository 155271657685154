import { Duplexer } from '@wix/duplexer-js';

export default function createDuplexerConnection({
  duplexerSocketsServerUrl,
  getInstance,
  appDefId,
}) {
  const duplexerClient = new Duplexer(duplexerSocketsServerUrl, {
    instanceUpdater: { getInstance },
  });
  return duplexerClient.connect({ appDefId });
}

import UrlPattern from 'url-pattern';

export class Router {
  static resultIdentifier = '@@RouterResult';

  constructor() {
    this.routes = new Map();
    this.matchListeners = [];
  }

  add = (route, callback) => {
    this.routes.set(route, callback);
  };

  fallback = redirect => {
    this.fallbackRoute = redirect;
  };

  match = (pathname, prevMatches = []) => {
    const createFallbackResult = () => ({
      pathname,
      params: {},
      prevMatches,
      route: this.fallbackRoute,
      [Router.resultIdentifier]: true,
    });

    const triggerMatch = result => this.matchListeners.forEach(cb => cb(result));

    return new Promise((resolve, reject) => {
      if (prevMatches.length >= 5) {
        if (this.fallbackRoute) {
          return resolve(createFallbackResult());
        }
        return reject('too many redirects');
      }
      let match;
      const entries = this.routes.entries();
      let entry = entries.next();

      let pathToMatch = pathname;
      if (pathToMatch === '') {
        pathToMatch = '/';
      }
      if (pathToMatch !== '/') {
        pathToMatch = pathToMatch.replace(/\/+$/g, '');
      }

      while (!entry.done && !match) {
        const [route, callback] = entry.value;
        const pattern = new UrlPattern(route, {
          segmentValueCharset: 'a-zA-Z0-9-_~ % \\.\\#\\!\\@\\[\\]\\$\\+\\*',
        });
        match = pattern.match(pathToMatch);

        if (match) {
          const result = {
            pathname,
            route,
            params: match,
            prevMatches,
            [Router.resultIdentifier]: true,
          };

          triggerMatch(result);

          if (callback) {
            Promise.resolve(
              callback(result, pathname => this.match(pathname, prevMatches.concat(result))),
            )
              .then(val => {
                resolve(val && val[Router.resultIdentifier] ? val : result);
              })
              .catch(reject);
          } else {
            resolve(result);
          }
        }

        entry = entries.next();
      }

      if (!match && this.fallbackRoute) {
        const result = createFallbackResult();
        triggerMatch(result);
        return resolve(result);
      }

      if (!match) {
        reject('failed to match route');
      }
    });
  };

  onMatch = callback => this.matchListeners.push(callback);
}

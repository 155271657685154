import { fetchComment } from './fetch-comment';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';

export const INCREMENT_COMMENT_LIKE_COUNT_REQUEST = 'comment/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_COMMENT_LIKE_COUNT_SUCCESS = 'comment/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_COMMENT_LIKE_COUNT_FAILURE = 'comment/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementCommentLikeCountRequest = ({ commentId, postId, currentUser }) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  payload: { _id: commentId, postId, currentUser },
});
export const incrementCommentLikeCountFailure = ({ commentId, postId, currentUser }) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
  payload: { _id: commentId, postId, currentUser },
});
export const incrementCommentLikeCountSuccess = ({
  commentId,
  postId,
  currentUser,
  count,
  isLiked,
}) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
  payload: { _id: commentId, postId, currentUser, likeCount: count, isLiked },
});

export function incrementCommentLikeCount({ commentId, postId }) {
  return (dispatch, getState, { request }) => {
    const currentUser = getCurrentUser(getState());
    dispatch(incrementCommentLikeCountRequest({ commentId, postId, currentUser }));
    const promise = request.post(`/posts/${postId}/comments/${commentId}/likes`);
    return promise
      .then(data => {
        dispatch(incrementCommentLikeCountSuccess({ commentId, postId, currentUser, ...data }));
      })
      .catch(() => dispatch(incrementCommentLikeCountFailure({ commentId, postId, currentUser })))
      .then(() => promise);
  };
}

import { get, isUndefined } from 'lodash';
import {
  LAYOUT_TYPE_PATH,
  CARD_SIZE_PATH,
  IS_MAIN_PAGE_HEADER_ENABLED,
  IS_HEADER_ENABLED,
  MAIN_PAGE_HEADER_HEIGHT,
  HEADER_HEIGHT,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { UPDATE_APP_SETTINGS_REQUEST } from '@wix/communities-forum-client-commons/dist/src/store/app-settings/app-settings-actions';
import getLayoutName from '../services/get-layout-name';
import {
  PICK_FORUM_SUBJECT,
  CHOOSE_FORUM_STRUCTURE,
  CUSTOMIZE_RCE,
  CUSTOMIZE_FEED,
  LOGIN_FROM_FORUM,
  SHOW_HEADER,
  RESIZE_HEADER,
  DELETE_CATEGORY,
  SELECT_FONT,
  SELECT_FONT_SIZE,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  SHOW_SUBCATEGORIES,
  SHOW_FOOTER_POSTS,
  MAKE_CATEGORY_CHILD_OR_PARENT,
  SET_POST_LIMIT,
  DISCARD_CHANGES_CATEGORY,
  MOVE_POST,
} from '../actions/user-events';
import { getCategory } from '../../common/selectors/categories-selectors';
import { serializeToggleState } from './bi-helpers';
import { getCategoryPostType } from '@wix/communities-forum-client-commons/dist/src/services/category-utils';
import {
  getAllGroupsMembersCount,
  getEntitiesCountsWithAccess,
} from '../selectors/manage-category-members-selectors';
import {
  isMembersOnly,
  isPrivate,
  isVisibleToMembersOnly,
} from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { COVER_TYPE_IMAGE } from '../constants/cover-types';
import {
  getCategoryInEdit,
  getInitialCopy,
  getCategoryInEditId,
} from '../selectors/categories-manager-selectors';
import { CLOSE_MODAL, OPEN_MODAL } from '../../common/modals/framework/store/modal-actions';
import {
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM,
} from '../components/modals/manage-category-members-modal/manage-category-members-modal-type';

import { getViewMode } from '../../common/selectors/basic-data-selector';
import { getMembersGroupsByIds } from '@wix/communities-forum-client-commons/dist/src/selectors/members-groups-selectors';
import forumsUsersSchemaLogger from '@wix/bi-logger-forums-users';
import { SHOW_MESSAGE } from '../../common/messages/framework/store/message-actions';
import { ACTIVATE_SUBSCRIPTIONS } from '../components/messages/message-types';
import { BUTTON_CLICKED } from '../actions/button-clicked';

export const eventMap = {
  [UPDATE_APP_SETTINGS_REQUEST]: action => {
    if (action.meta && action.meta.bi && action.meta.bi.externalId !== 'draft') {
      return {
        evid: 12,
        setting_input_json: JSON.stringify({
          layoutName: getLayoutName(
            get(action.payload, LAYOUT_TYPE_PATH),
            get(action.payload, CARD_SIZE_PATH),
          ),
        }),
      };
    }
  },
  [PICK_FORUM_SUBJECT]: action => ({
    evid: 41,
    type_of_subject: action.payload,
  }),
  [CHOOSE_FORUM_STRUCTURE]: action => ({
    evid: 302,
    type: action.payload ? 'ListOfCategories' : 'ListOfAllPosts',
  }),
  [CUSTOMIZE_RCE]: action => ({
    evid: 565,
    feature: action.payload.plugin,
    type: serializeToggleState(action),
  }),
  [CUSTOMIZE_FEED]: action => ({
    evid: 44,
    activity_type: action.payload.type,
    type: serializeToggleState(action),
    editor_view_mode: action.payload.editorViewMode,
  }),
  [LOGIN_FROM_FORUM]: action => ({
    evid: 45,
    option: action.payload.enabled ? 'on' : 'off',
    editor_view_mode: action.payload.editorViewMode,
  }),
  [SHOW_HEADER]: action => {
    const origin = action.payload.origin;

    if (origin === IS_MAIN_PAGE_HEADER_ENABLED) {
      return {
        evid: 42,
        option: serializeToggleState(action),
        origin: 'mainPage',
      };
    }
    if (origin === IS_HEADER_ENABLED) {
      return {
        evid: 42,
        option: serializeToggleState(action),
        origin: 'postsPage',
      };
    }
  },
  [RESIZE_HEADER]: action => {
    const origin = action.payload.origin;
    const size = action.payload.size;

    if (origin === MAIN_PAGE_HEADER_HEIGHT) {
      return {
        evid: 43,
        height: size,
        origin: 'mainPage',
      };
    }
    if (origin === HEADER_HEIGHT) {
      return {
        evid: 43,
        height: size,
        origin: 'postsPage',
      };
    }
  },
  [DELETE_CATEGORY]: (action, state) => {
    const category = getCategory(state, action.payload.categoryId);
    return {
      application_type: 'web',
      evid: 34,
      posts_format: getCategoryPostType(category),
      category_id: category._id,
      name: category.label,
      origin: action.payload.origin,
      type: category.parentId ? 'subcategory' : 'category',
      who_can_create_posts: category.isWriteProtected ? 'admins_and_moderators' : 'all_members',
      category_access: getCategoryAccess(category),
    };
  },
  [EDIT_CATEGORY]: (action, state) => {
    const category = getCategory(state, action.payload.categoryId);
    const {
      badges: badge_count,
      roles: role_count,
      siteMembers: member_count,
      plans: paid_plan_count,
    } = getEntitiesCountsWithAccess(state, category._id).assigned;
    const {
      paidPlanMemberCount: paid_plan_member_count,
      badgeMemberCount: badge_member_count,
      roleMemberCount: role_member_count,
    } = getAllGroupsMembersCount(state, category._id);
    return {
      application_type: 'web',
      evid: 32,
      access_groups_json: JSON.stringify(getMembersGroupsByIds(state, category.groups || [])),
      posts_format: getCategoryPostType(category),
      category_id: category._id,
      name: category.label,
      origin: action.payload.origin,
      isChanged: action.payload.isChanged,
      type: category.parentId ? 'subcategory' : 'category',
      paid_plan_member_count,
      badge_member_count,
      role_member_count,
      paid_plan_count,
      badge_count,
      role_count,
      member_count,
      who_can_create_posts: category.isWriteProtected ? 'admins_and_moderators' : 'all_members',
      category_access: getCategoryAccess(category),
      prev_category_access: getCategoryAccess(action.payload.initialCopy),
      is_category_image_set: category.coverType === COVER_TYPE_IMAGE,
      visible_to_everyone: isPrivate(category) ? !isVisibleToMembersOnly(category) : null,
    };
  },
  [CREATE_CATEGORY]: (action, state) => {
    const category = action.payload.category;
    const {
      badges: badge_count,
      roles: role_count,
      siteMembers: member_count,
      plans: paid_plan_count,
    } = getEntitiesCountsWithAccess(state, category._id).assigned;
    const {
      paidPlanMemberCount: paid_plan_member_count,
      badgeMemberCount: badge_member_count,
      roleMemberCount: role_member_count,
    } = getAllGroupsMembersCount(state, category._id);
    return {
      application_type: 'web',
      evid: 33,
      access_groups_json: JSON.stringify(getMembersGroupsByIds(state, category.groups || [])),
      posts_format: getCategoryPostType(category),
      category_id: category._id,
      name: category.label,
      origin: action.payload.origin,
      type: category.parentId ? 'subcategory' : 'category',
      paid_plan_member_count,
      badge_member_count,
      role_member_count,
      paid_plan_count,
      badge_count,
      role_count,
      member_count,
      who_can_create_posts: category.isWriteProtected ? 'admins_and_moderators' : 'all_members',
      category_access: getCategoryAccess(category),
      is_category_image_set: category.coverType === COVER_TYPE_IMAGE,
      visible_to_everyone: isPrivate(category) ? !isVisibleToMembersOnly(category) : null,
    };
  },
  [SELECT_FONT]: action => {
    return {
      evid: 52,
      font: action.payload.font,
      place: action.payload.place,
    };
  },
  [SELECT_FONT_SIZE]: action => {
    return {
      evid: 53,
      fontSize: action.payload.size,
      place: action.payload.place,
    };
  },
  [MAKE_CATEGORY_CHILD_OR_PARENT]: (action, state) => {
    const { method, oldCategory, updatedCategory } = action.payload;
    const parentCategory = getCategory(state, updatedCategory.parentId);
    return {
      evid: 55,
      application_type: 'web',
      method,
      origin: getViewMode(state),
      category_id: updatedCategory._id,
      type: oldCategory.parentId ? 'subcategory' : 'category',
      type_chosen: updatedCategory.parentId ? 'subcategory' : 'category',
      parent_category_access: parentCategory && getCategoryAccess(parentCategory),
      parent_category_id: updatedCategory.parentId,
      prev_category_access: getCategoryAccess(oldCategory),
      category_access: getCategoryAccess(updatedCategory),
    };
  },
  [SHOW_SUBCATEGORIES]: action => ({
    evid: 63,
    option: serializeToggleState(action),
    editor_view_mode: action.payload.editorViewMode,
  }),
  [SHOW_FOOTER_POSTS]: action => ({
    evid: 64,
    option: serializeToggleState(action),
    editor_view_mode: action.payload.editorViewMode,
  }),
  [SET_POST_LIMIT]: action => ({
    evid: 65,
    option: isUndefined(action.payload) ? 'no_limit' : `${action.payload}_per_day`,
  }),
  [DISCARD_CHANGES_CATEGORY]: (action, state) => {
    const category = getCategoryInEdit(state);
    const initialCopy = getInitialCopy(state);

    if (!category) {
      return;
    }
    return {
      evid: 577,
      posts_format: getCategoryPostType(category),
      category_access: getCategoryAccess(category),
      prev_category_access: getCategoryAccess(initialCopy),
      is_changed: action.payload.isChanged,
      application_type: 'web',
    };
  },
  [MOVE_POST]: (action, state) => {
    const category = getCategory(state, action.payload.categoryId);
    const sourceCategory = getCategory(state, action.payload.sourceCategoryId);

    return {
      evid: 579,
      category_posts_format: getCategoryPostType(category),
      source_category_posts_format: getCategoryPostType(sourceCategory),
      source_category_id: action.payload.sourceCategoryId,
      category_id: action.payload.categoryId,
      origin: action.payload.origin,
      post_id: action.payload.postId,
    };
  },
  [CLOSE_MODAL]: (action, state) => {
    const type = action.payload.type;
    switch (type) {
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS:
        const items_updated = get(action, ['payload', 'resolve', 'itemsUpdated'], false);
        return {
          evid: 584,
          access_type: getCategoryAccessModalType(type),
          category_id: getCategoryInEditId(state),
          items_updated,
        };
      default:
        return;
    }
  },
  [OPEN_MODAL]: (action, state) => {
    const { type } = action.payload;
    switch (type) {
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES:
      case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS:
        return {
          evid: 585,
          access_type: getCategoryAccessModalType(type),
          category_id: getCategoryInEditId(state),
        };
      default:
        return;
    }
  },
  [BUTTON_CLICKED]: action => {
    switch (action.payload.name) {
      case 'activate_subscriptions':
        return {
          evid: 68,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
  [SHOW_MESSAGE]: action => {
    switch (action.payload.type) {
      case ACTIVATE_SUBSCRIPTIONS:
        return {
          evid: 67,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
};

function getCategoryAccessModalType(type) {
  switch (type) {
    case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM:
      return 'members';
    case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES:
      return 'roles';
    case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES:
      return 'badges';
    case MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS:
      return 'paid_plans';
    default:
      return;
  }
}

function getCategoryAccess(category) {
  if (isPrivate(category)) {
    return 'selected_members';
  } else if (isMembersOnly(category)) {
    return 'members_only';
  } else {
    return 'everyone';
  }
}

export const endpoint = 'forums-users';
export const src = 69;
export const schemaLogger = forumsUsersSchemaLogger;

import { isEmpty } from 'lodash';
import {
  FORUM_APP_ID,
  FORUM_SECTION_ID,
} from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';
import createAction from '../../common/services/create-action';
import { getPaidPlansSectionUrl } from '../services/get-paid-plans-section-url';

export const NAVIGATE_TO_PAID_PLANS = 'app/NAVIGATE_TO_PAID_PLANS';

const navigateToPaidPlansAction = createAction(NAVIGATE_TO_PAID_PLANS);

export const navigateToPaidPlans = (returnUrl = '', planIds, titleText, buttonText) => (
  dispatch,
  getState,
  { wixCodeApi },
) => {
  const queryParams = {};
  if (returnUrl) {
    queryParams.navigateToSectionProps = btoa(
      JSON.stringify({
        sectionId: FORUM_SECTION_ID,
        appDefinitionId: FORUM_APP_ID,
        state: returnUrl,
        shouldRefreshIframe: true,
      }),
    );
  }
  if (planIds) {
    queryParams.planIds = planIds.join(',');
  }
  if (titleText || buttonText) {
    const content = {};
    if (titleText) {
      content.titleText = titleText;
    }
    if (buttonText) {
      content.buttonText = buttonText;
    }
    try {
      queryParams.verticalStatusContent = btoa(JSON.stringify(content)); // this throws with base-64 chars (japan language for example)
    } catch (error) {}
  }
  let path = '';
  if (!isEmpty(queryParams)) {
    path = `?appSectionParams=${encodeURIComponent(JSON.stringify(queryParams))}`;
  }
  const sectionUrl = getPaidPlansSectionUrl(wixCodeApi);
  dispatch(navigateToPaidPlansAction({ path }));
  return wixCodeApi.location.to(`${sectionUrl}${path}`);
};

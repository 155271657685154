import { FORUM_SECTION_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.dev';
import createAction from '../../services/create-action';
import { transformWixCodeLocation } from './transform-wix-code-location';
import { FORUM_APP_ID } from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';

export const SET_LOCATION = 'location/SET';

export const setLocation = createAction(SET_LOCATION);

export const initializeLocation = () => (dispatch, getState, { wixCodeApi }) => {
  return Promise.resolve(
    wixCodeApi.site.getSectionUrl({ sectionId: FORUM_SECTION_ID, appDefinitionId: FORUM_APP_ID }),
  ).then(({ url }) => dispatch(setLocation(transformWixCodeLocation(wixCodeApi.location, url))));
};

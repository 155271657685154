import { combineReducers } from 'redux';
import { communitiesContext } from '../../common/store/communities-context/communities-context-reducer';
import { componentData } from '../../common/store/component-data/component-data-reducer';
import { experiments } from './experiments';
import recentPosts from './recent-posts';
import { location } from '../../common/store/location/location-reducer';
import { currentUser } from '../../common/store/current-user/current-user-reducer';
import { categories } from './categories';
import { basicParams } from '../../common/store/basic-params/basic-params-reducer';
import { instanceValues } from '../../common/store/instance-values/instance-values-reducer';
import { translations } from '../../common/store/translations/translations-reducer';
import { debugState } from '../../common/store/debug-state/debug-state-reducer';
import { appLoaded } from '../../common/store/app-loaded/app-loaded-reducer';
import { promisifiedActionsResults } from '../../common/actions-promisifier/store/promisified-actions-reducer';
import { savedAction } from '../../common/store/saved-action/saved-action-reducer';
import { maNavigation } from '../../common/store/ma-navigation/ma-navigation-reducer';
import { badges } from '../../app/reducers/badges';
import forumData from '../../app/reducers/forum-data';
import { combineReducersR } from '@wix/communities-forum-client-commons/dist/src/services/redux-r';
import { counters } from '../../app/reducers/counters';

export default combineReducersR(combineReducers)({
  appLoaded,
  debugState,
  communitiesContext,
  componentData,
  experiments,
  basicParams,
  instanceValues,
  recentPosts,
  location,
  currentUser,
  categories,
  translations,
  promisifiedActionsResults,
  savedAction,
  maNavigation,
  badges,
  forumData,
  counters,
});

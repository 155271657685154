import { FETCH_RCE_TRANSLATIONS_SUCCESS } from '../actions/fetch-rce-translations';

const initialState = null;

export default function rceTranslationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RCE_TRANSLATIONS_SUCCESS:
      return action.payload || initialState;
    default:
      return state;
  }
}

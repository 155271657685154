import createAction from '../services/create-action';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';
export const FETCH_EXPERIMENTS_FAILURE = 'experiments/FETCH_EXPERIMENTS_FAILURE';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);
export const fetchExperimentsFailure = createAction(FETCH_EXPERIMENTS_FAILURE);

export function fetchExperiments() {
  return (dispatch, getState, { request }) => {
    dispatch(fetchExperimentsRequest());

    const promise = request('/experiments');

    return promise.then(
      body => dispatch(fetchExperimentsSuccess(body)),
      () => dispatch(fetchExperimentsFailure()),
    );
  };
}

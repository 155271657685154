import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import createAction from '../../common/services/create-action';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { extractMentionsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { closeModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';

export const CREATE_POST_REQUEST = 'post/CREATE_REQUEST';
export const CREATE_POST_SUCCESS = 'post/CREATE_SUCCESS';
export const CREATE_POST_FAILURE = 'post/CREATE_FAILURE';

export const createPostRequest = createAction(CREATE_POST_REQUEST);
export const createPostSuccess = createAction(CREATE_POST_SUCCESS);
export const createPostFailure = createAction(
  CREATE_POST_FAILURE,
  () => undefined,
  meta => meta,
);

export function createPost(post = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createPostRequest());
    const postSanitized = sanitizeContent(post);
    const promise = request.post(`/posts/`, {
      ...postSanitized,
      hashtags: extractHashtagsFromContent(postSanitized.content),
      mentions: extractMentionsFromContent(postSanitized.content),
    });

    return promise
      .then(
        post => dispatch(createPostSuccess(post)),
        response => dispatch(createPostFailure(response)),
      )
      .then(() => promise);
  };
}

export const createPostAndNavigateToItPromisified = onBeforePostCreateHook =>
  createPromisifiedAction(
    postRaw => {
      return (dispatch, getState) => {
        let promise = Promise.resolve();
        if (onBeforePostCreateHook.hasHook()) {
          promise = promise.then(() =>
            onBeforePostCreateHook.exec(postRaw).catch(createExternalHookRejectHandler(dispatch)),
          );
        }

        return promise
          .then(r =>
            dispatch(createPost(isExternalHookResolve(r) ? r.payload || postRaw : postRaw)),
          )
          .then(post => {
            const category = getCategory(getState(), post.categoryId);
            dispatch(closeModal({ type: MODAL_TYPE_CREATE_POST, resolve: false }));
            dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
          })
          .catch(response => ({
            isSuccessful: false,
            status: response.status,
          }));
      };
    },
    ({ isSuccessful = true, status } = {}) => ({ isSuccessful, status }),
  );

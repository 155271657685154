import { createStateReceiver } from '@wix/communities-forum-client-commons/dist/src/services/redux-r';

const stateReceiver = createStateReceiver();

export function subscribeToChange(store, onChange) {
  store.subscribe(() => {
    const { state, prevState, diff } = stateReceiver(store.getState());
    if (
      diff.length === 0 || // if state hasn't changed we dont want to transmit props through the postMessage ("bridge")
      (state.globalLoader &&
        state.globalLoader.isVisible &&
        prevState &&
        prevState.globalLoader.isVisible) // also if global loader is visible - we also don't want to transmit changes, since they are meaningless with loader
    ) {
      return;
    }
    return onChange(state);
  });
}

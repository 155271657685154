import { trim } from 'lodash';
import { getPreviousMatches, getRouteParams } from '../../common/router/router-selectors';
import { getLocation } from '../../common/store/location/location-selectors';
import { getCategories } from '../../common/selectors/categories-selectors';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';

const getPathBeforeSearch = state => {
  const prevMatches = getPreviousMatches(state) || [];
  const actualPrevPath = prevMatches.find(({ pathname }) => !pathname.startsWith('/search')) || {};
  return trim(actualPrevPath.pathname, '/');
};

export const goBack = () => (dispatch, getState) => {
  const state = getState();
  const categories = getCategories(state);
  const hasOneCategory = categories.length === 1;
  const currentLocation = getLocation(state);
  const parts = trim(currentLocation.pathname, '/').split('/');
  parts.pop();

  if (currentLocation.pathname.startsWith('/search/')) {
    parts.splice(-1, 1, getPathBeforeSearch(state));
  } else if (currentLocation.pathname.startsWith('/search')) {
    parts.push(trim(currentLocation.prevPath, '/'));
  }
  if (getRouteParams(state).page) {
    parts.pop();
  }
  if (hasOneCategory && parts.length === 0) {
    parts.push(categories[0].slug);
  }
  const path = '/' + parts.join('/');

  return dispatch(navigateWithinForum(path));
};

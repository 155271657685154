import { combineReducers } from 'redux';
import { routerReducer } from '../../common/router';
import comments from './comments';
import { communitiesContext } from '../../common/store/communities-context/communities-context-reducer';
import commentEdit from './edited-comment-id';
import forumData from './forum-data';
import isLoaded from './is-loaded';
import isLoading from './is-loading';
import { membersGroups } from './members-groups';
import { location } from '../../common/store/location/location-reducer';
import { maNavigation } from '../../common/store/ma-navigation/ma-navigation-reducer';
import pagination from './pagination';
import posts from './posts';
import search from './search';
import categories from './categories';
import typing from './typing';
import rceTranslations from './rce-translations';
import categoriesManager from './categories-manager';
import { sorting } from './sorting';
import { filtering } from './filtering';
import { deepLinkRouting } from './deep-link-routing';
import { translations } from '../../common/store/translations/translations-reducer';
import { basicParams } from '../../common/store/basic-params/basic-params-reducer';
import { experiments } from './experiments';
import { modalReducer } from '../../common/modals/framework/initialize-store';
import { messageReducer } from '../../common/messages/framework/initialize-store';
import { uploadedRegistry } from './uploaded-registry';
import { currentUser } from '../../common/store/current-user/current-user-reducer';
import { promisifiedActionsResults } from '../../common/actions-promisifier/store/promisified-actions-reducer';
import { debugState } from '../../common/store/debug-state/debug-state-reducer';
import { instanceValues } from '../../common/store/instance-values/instance-values-reducer';
import { fastFormSubmit } from '../../common/components/fast-form/store/fast-form-reducer';
import { appLoaded } from '../../common/store/app-loaded/app-loaded-reducer';
import { footerPosts } from './footer-posts';
import { relatedPosts } from './related-posts';
import { badges } from './badges';
import { paidPlans } from './paid-plans';
import { styleParams } from '../../common/store/style-params/style-params-reducer';
import { savedAction } from '../../common/store/saved-action/saved-action-reducer';
import { manageCategoryMembers } from './manage-category-members';
import { siteMembers } from '../../common/store/site-members/site-members-store';
import { offlineMigration } from './offline-migration';
import { reloadPage } from './reload-page';
import { globalLoader } from './global-loader';
import { whoLiked } from './who-liked';
import { siteId } from './site-id';
import { externalLinks } from './external-links';
import { similarPosts } from './similar-posts';
import { localStorage } from './local-storage';
import { combineReducersR } from '@wix/communities-forum-client-commons/dist/src/services/redux-r';
import { MODAL_TYPE_CREATE_COMMENT } from '../components/modals/comment-create-modal/comment-create-modal-type';
import { counters } from './counters';

export default combineReducersR(combineReducers)({
  appLoaded,
  fastFormSubmit,
  debugState,
  promisifiedActionsResults,
  uploadedRegistry,
  experiments,
  translations,
  basicParams,
  ...modalReducer({ allowUpdateToModals: [MODAL_TYPE_CREATE_COMMENT] }),
  ...routerReducer,
  ...messageReducer,
  comments,
  communitiesContext,
  commentEdit,
  forumData,
  instanceValues,
  isLoaded,
  isLoading,
  membersGroups,
  location,
  maNavigation,
  pagination,
  posts,
  search,
  categories,
  typing,
  rceTranslations,
  categoriesManager,
  currentUser,
  sorting,
  deepLinkRouting,
  footerPosts,
  relatedPosts,
  badges,
  paidPlans,
  styleParams,
  savedAction,
  manageCategoryMembers,
  siteMembers,
  filtering,
  offlineMigration,
  reloadPage,
  globalLoader,
  whoLiked,
  siteId,
  similarPosts,
  externalLinks,
  localStorage,
  counters,
});

import _ from 'lodash';
import { FLUSH } from 'redux-throttle';
import { CANCEL } from '@sarunas/redux-debounce';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { sendEventToChannel } from './duplexer-actions';
import { getTypingUserIds } from '../selectors/typing-selectors';
import * as WS_EVENTS from '../../universal/constants/ws-events';
import { DUPLEXER_EVENTS, DUPLEXER_CHANNELS } from '../../universal/constants/duplexer';
import createAction from '../../common/services/create-action';

export const ADD_TYPING_USER = 'typing/ADD_USER';
export const REMOVE_TYPING_USER = 'typing/REMOVE_USER';
export const CLEAR_TYPING_USERS = 'typing/CLEAR_USERS';
export const START_TYPING_DEBOUNCED = 'typing/START_DEBOUNCED';

export const SOCKET_TYPING_MESSAGE = WS_EVENTS.TYPING_MESSAGE;

export function emitTyping(postId, user) {
  return dispatch => {
    const eventPayload = { user, post: { _id: postId } };

    dispatch(
      sendEventToChannel({
        channelName: DUPLEXER_CHANNELS.POST,
        resourceId: postId,
        eventName: DUPLEXER_EVENTS.TYPING,
        eventPayload,
      }),
    );
  };
}

const EMIT_TYPING_TIMEOUT = 2000;
const REMOVE_TYPING_USER_TIMEOUT = 4000;

export function emitTypingThrottled(postId, user) {
  const action = emitTyping(postId, user);
  action.type = START_TYPING_DEBOUNCED;
  action.meta = {
    throttle: {
      wait: EMIT_TYPING_TIMEOUT,
      leading: true,
      trailing: true,
    },
  };
  return action;
}

export const flushTypingThrottled = createAction(FLUSH, () => ({ type: START_TYPING_DEBOUNCED }));

export function addTypingUser({ user }) {
  return (dispatch, getState) => {
    if (user.siteMemberId === _.get(getCurrentUser(getState()), 'siteMemberId')) {
      return;
    }
    dispatch({
      type: ADD_TYPING_USER,
      payload: user,
    });
  };
}

export const removeTypingUser = ({ user }) => dispatch =>
  dispatch({
    type: REMOVE_TYPING_USER,
    payload: user,
  });

const removeTypingUserDebouncedType = userId => `REMOVE_TYPING_USER_${userId}_DEBOUNCED`;

export function removeTypingUserDebounced(payload) {
  const action = removeTypingUser(payload);
  action.type = removeTypingUserDebouncedType(payload.user.siteMemberId);
  action.meta = { debounce: REMOVE_TYPING_USER_TIMEOUT };
  return action;
}

export const clearTypingUsers = () => (dispatch, getState) => {
  dispatch({
    type: CANCEL,
    payload: {
      type: _.map(getTypingUserIds(getState()), removeTypingUserDebouncedType),
    },
  });

  dispatch({ type: CLEAR_TYPING_USERS });
};

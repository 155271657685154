import uri from 'urijs';
import createAction from '../../services/create-action';
import { getCurrentInstance } from '../../services/get-instance';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export function setBasicParams({ viewMode, language, pageNumber }) {
  return (dispatch, getState, { wixCodeApi, appParams }) => {
    const showMobileView =
      wixCodeApi.location.url && uri(wixCodeApi.location.url).hasQuery('showMobileView', 'true');

    const apiBaseUrl =
      wixCodeApi.window.rendering.env === 'backend'
        ? appParams.baseUrls.apiBaseUrl
        : appParams.baseUrls.apiBaseUrlClient;

    return dispatch(
      setBasicParamsAction({
        viewMode,
        instance: getCurrentInstance(wixCodeApi),
        formFactor: showMobileView ? 'Mobile' : wixCodeApi.window.formFactor,
        isSSR: wixCodeApi.window.rendering.env === 'backend',
        isSeo: wixCodeApi.seo.isInSEO(),
        language,
        apiBaseUrl,
        isDebug: wixCodeApi.location.query.debug,
        isProduction: process.env.NODE_ENV === 'production',
        pageNumber,
      }),
    );
  };
}

export const FETCH_RCE_TRANSLATIONS_REQUEST = 'FETCH_RCE_TRANSLATIONS_REQUEST';
export const FETCH_RCE_TRANSLATIONS_SUCCESS = 'FETCH_RCE_TRANSLATIONS_SUCCESS';
export const FETCH_RCE_TRANSLATIONS_FAILURE = 'FETCH_RCE_TRANSLATIONS_FAILURE';

export const fetchRceTranslationsRequest = () => ({ type: FETCH_RCE_TRANSLATIONS_REQUEST });
export const fetchRceTranslationsSuccess = payload => ({
  type: FETCH_RCE_TRANSLATIONS_SUCCESS,
  payload,
});
export const fetchRceTranslationsFailure = () => ({ type: FETCH_RCE_TRANSLATIONS_FAILURE });

export function fetchRceTranslations(language) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchRceTranslationsRequest());

    const promise = request(`/rce/translations/${language}`);
    return promise.then(
      category => dispatch(fetchRceTranslationsSuccess(category)),
      () => dispatch(fetchRceTranslationsFailure()),
    );
  };
}

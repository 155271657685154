import uri from 'urijs';
import createAction from '../../common/services/create-action';

export const FETCH_FOOTER_POSTS_REQUEST = 'posts/FETCH_FOOTER_POSTS_REQUEST';
export const FETCH_FOOTER_POSTS_SUCCESS = 'posts/FETCH_FOOTER_POSTS_SUCCESS';
export const FETCH_FOOTER_POSTS_FAILURE = 'posts/FETCH_FOOTER_POSTS_FAILURE';

export const fetchFooterPostsRequest = createAction(FETCH_FOOTER_POSTS_REQUEST);
export const fetchFooterPostsSuccess = createAction(FETCH_FOOTER_POSTS_SUCCESS);
export const fetchFooterPostsFailure = createAction(FETCH_FOOTER_POSTS_FAILURE);

export function fetchFooterPosts({ categoryId, excludeSlug } = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchFooterPostsRequest(undefined, { categoryId }));

    const params = {
      offset: 0,
      size: 3,
      ...(categoryId ? { categoryId } : {}),
      ...(excludeSlug ? { excludeSlugs: excludeSlug } : {}),
    };

    const promise = request(uri('/posts/feed/createdDate').query({ ...params }), {
      parseHeaders: true,
    });

    return promise.then(
      ({ body = [] }) => dispatch(fetchFooterPostsSuccess(body)),
      () => dispatch(fetchFooterPostsFailure()),
    );
  };
}
